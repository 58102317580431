/**
 * BE MINDFUL OF ADDING DEPENDENCIES TO THIS FILE.
 * This file is used in the login page and therefore handles sensitive
 * information. This is why we want to keep the dependencies small so we know
 * exactly what's running on it.
 */

document.addEventListener("DOMContentLoaded", initLogin);

export default async function initLogin() {
  const emailCheckForm = document.querySelector<HTMLFormElement>(
    "[data-js='email_check']"
  );
  const ssoForm = document.querySelector<HTMLFormElement>(
    "[data-js='sso_form']"
  );
  const oauthForm = document.querySelector<HTMLFormElement>(
    "[data-js='oauth_form']"
  );
  const nextBtn = document.querySelector<HTMLButtonElement>(
    "[data-js='next_btn']"
  );
  const emailField = document.querySelector<HTMLInputElement>(
    "[data-js='email_field']"
  );
  const userEmailField = document.querySelector<HTMLInputElement>(
    "[data-js='user_email']"
  );
  const ssoEmailField = document.querySelector<HTMLInputElement>(
    "[data-js='sso_email_field']"
  );
  const ssoLink = document.querySelector<HTMLLIElement>("[data-js='sso-link']");
  const forgotLink = document.querySelector<HTMLLIElement>(
    "[data-js='forgot_link']"
  );
  const backLink = document.querySelector<HTMLLIElement>(
    "[data-js='back_link']"
  );
  const resendLink = document.querySelector<HTMLLIElement>(
    "[data-js='resend_link']"
  );

  const userPassForm = document.querySelector<HTMLFormElement>("#new_user");

  backLink?.addEventListener("click", reset);
  emailField?.addEventListener("input", handleEmailChange);
  emailCheckForm?.addEventListener("submit", handleNextClick);

  function handleNextClick(event: Event) {
    event.preventDefault();
    if (
      emailCheckForm &&
      emailCheckForm.length &&
      emailCheckForm.checkValidity()
    ) {
      emailField && checkEmail(emailField.value);
    }
  }

  function reset() {
    userEmailField?.setAttribute("value", "");
    emailCheckForm?.classList.remove("d-none");
    userPassForm?.classList.add("d-none");
    ssoLink?.classList.add("d-none");
    forgotLink?.classList.add("d-none");
    forgotLink?.classList.add("d-none");
    backLink?.classList.add("d-none");
    resendLink?.classList.remove("d-none");
  }

  async function checkEmail(email: string) {
    const csrfTokenElement = document.querySelector<HTMLMetaElement>(
      '[name="csrf-token"]'
    );
    const headers: HeadersInit = {
      "Content-Type": "application/json",
    };
    if (csrfTokenElement) {
      headers["X-CSRF-Token"] = csrfTokenElement.content;
    }
    await fetch(`/dashboard/accounts/check`, {
      method: "POST",
      headers,
      body: JSON.stringify({
        email,
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }

        throw new Error("Received an invalid response from server");
      })
      .then((response: CheckResult | null) => {
        if (response) {
          if ("redirect" in response) {
            window.location.href = response.redirect;
            return;
          }

          if (response.ssoType === "saml") {
            enableSamlLogin(email);
          }

          if (response.ssoType === "oauth") {
            enableOauthLogin();
          }
        } else {
          enableUserPassLogin(email);
        }
      })
      .catch(() => {
        enableUserPassLogin(email);
      })
      .finally(() => {
        localStorage.removeItem("session-expiration-at");
      });
  }

  function enableSamlLogin(email: string) {
    ssoEmailField?.setAttribute("value", email);
    ssoForm?.submit();
  }

  function enableOauthLogin() {
    oauthForm?.submit();
  }

  function enableUserPassLogin(email: string) {
    userEmailField?.setAttribute("value", email);
    emailCheckForm?.classList.add("d-none");
    userPassForm?.classList.remove("d-none");
    ssoLink?.classList.remove("d-none");
    forgotLink?.classList.remove("d-none");
    backLink?.classList.remove("d-none");
    resendLink?.classList.add("d-none");
  }

  function handleEmailChange() {
    if (validateEmail(emailField?.value)) {
      nextBtn?.removeAttribute("disabled");
    } else {
      nextBtn?.setAttribute("disabled", "true");
    }
  }

  function validateEmail(email: string | undefined) {
    return email?.trim().length !== 0;
  }
}

type CheckResult = {
  ssoType: "saml" | "oauth";
} | { redirect: string };
